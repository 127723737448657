    const $tab_bar = $('.tab-bar')
    const $li = $tab_bar.find('li')
    const $tab_content = $('.tab-content')
    const $group1 = $tab_content.find('.group')

    $li.click(function(e) {
      e.preventDefault();
      let _index = $(this).index()

      $li.removeClass('selected')
      $(this).addClass('selected')

      $group1.hide()
      $group1.eq(_index).show()
      // $('#slick-slider').slick('refresh');
      // $(".popup-slider").slick('resize');
      $('.slide1')[0].slick.refresh()
      $('.slide2')[0].slick.refresh()
      $('.slide3')[0].slick.refresh()
    })
    $(window).load(function() {
      setTimeout(() => {
        $group1.eq(0).show().siblings().hide()
      }, 300)

    });