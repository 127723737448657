$(function(){ 
  // let _w=$(window).width();
  // const nav=$('.top')
  // $(window).resize(function(){
  //     let _w=$(window).width();
  //     if(_w>960 &&_st<100){
  //       nav.stop().animate({
  //         top:-150
  //       })

          
  //     }else{
  //       nav.stop().animate({
  //         top:0
  //       })


  //     }
  // }).resize();

  // nav原本隱藏, 畫面往下才會下滑出現
  // $(window).on('scroll',function(){
  //   let _st=$(window).scrollTop();
  //   if(_w>960){
  //     if(_st>100){
  //       nav.stop().animate({
  //         top:0
  //       })
  //     }else{
  //       nav.stop().animate({
  //         top:-150
  //       })

  //     }
  //   }

  // })
     
})
